*,
*::after,
*::before {
	box-sizing: border-box;
}

body {
	--color-text: #ececec;
	--color-bg: #080B10;
	--color-link: #000;
	--color-link-hover: #000;
	--color-info: #000;

	--color-light-primary: #46E0E0;
	--color-light-secondary: #00979E;
	--color-dark-primary: #173337;
	--color-dark-secondary:#080B10;

	--text-color-1:#F2F2F2;
	--text-color-2:#B3B3B3;
	--text-color-3:#666666;
	--text-color-4:#1A1A1A;

	min-height: 100vh;
	color: var(--color-text);
	background-color: #fff;
	background-color: var(--color-dark-secondary);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/**Team Heading**/
.team__heading {
	display: block;
	margin: 5rem 0;
}

.team__heading h1{
	text-align: center;
    font-size: 6em;
	letter-spacing: 4px;
	color: #fff;
	font-family: 'Lexend Deca', sans-serif;
}

.team__head {
	display: block;
}

.team__head h1{
	text-align: center;
    font-size: 3rem;
	letter-spacing: 2px;
	color: #fff;
	font-family: 'Lexend Deca', sans-serif;
}
/**Team Heading**/

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
}

a:hover,
a:focus {
	color: var(--color-link-hover);
	outline: none;
}

.content {
	position: relative;
	margin: 20px auto;
}

.grid {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 60px;
	width: 100%;
	max-width: 1440px;
	margin: 0 auto;
	padding-bottom: 10rem;
}

@media screen and (min-width: 1150px) {
	.grid {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 4rem 3rem 4rem 3rem;

		/* grid-row-gap: 2rem;
		grid-template-columns: repeat(3,calc(100% / 3)); */
	}
}

@media screen and (max-width: 1150px) {
	.content {
		flex-direction: column;
		height: auto;
		min-height: 0;
	}
	.content__fixed {
		position: relative;
		z-index: 1000;
		display: block;
		padding: 0.85em;
	}
	.grid {
		margin-top: 100px;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 5rem;
	}
}


/**** Card ****/

.card {
    padding: 15px;
    width: 310px;
	height: 470px;
	margin: 0 2rem 0 2rem;
    background: #191a1c;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.7);
	border: 3px solid#46E0E0;
    user-select: none;

	/* scale: 1;
	transition: all 200ms; */
}

.card:hover {
	transition: 0.2s linear;
	scale: 1.03;
}

.cover {
	background-size: cover;
    height: 180px;
    border-radius: 5px 5px 0 0;
}

.profile {
	object-fit: cover;
	object-position: center;
	height: 190px;
    width: 190px;
    border-radius: 50%;
    border: 2px dotted #191a1c;
    background: #191a1c;
    padding: 5px;
}

.member_name {
    font-size: 2.2rem;
	letter-spacing: 3px;
	font-family: 'Lexend Deca', sans-serif;
    margin: 50px auto 10px;
    color: var(--text-color-1);
}

.por {
	font-size: 1.2rem;
	font-family: 'Lexend Deca', sans-serif;
	color: var(--text-color-2);
}

.info {
    margin: 40px auto;
    line-height: 1.6;
}

.socials {
	font-size: 2rem;
    width: 180px;
    margin: 2rem auto 10px;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.socials a {
	color: var(--text-color-2);
}

.linkedin:hover {
	color: #0077B5;
	cursor: pointer;
	transition: 0.28s ease-in-out;
}

.insta:hover {
	color: #BB2668;
	cursor: pointer;
	transition: 0.28s ease-in-out;
}

.facebook:hover {
	color: #f7f7f7;
	cursor: pointer;
	transition: 0.28s ease-in-out;
}

.twitter:hover {
	color: #1C96E9;
	cursor: pointer;
	transition: 0.28s ease-in-out;
}

@media screen and (max-width: 550px) {
    .card {
		width: 270px;
	}

	.profile {
		height: 150px;
		width: 150px;
	}

	.member_name {
		margin: 1rem auto 10px;
	}
}

/**** Card ****/