@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
div.navb{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} 
 div.navb {
     /* position: -webkit-sticky;  */
     position: sticky;
    top: 0;
    z-index: 100;
}
.navbar{
  display: flex;
  height: 100px;
  width: 100%;
  background: #1b1b1b;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  flex-wrap: wrap;
}
.navbar .logo{
  color: #fff;
  font-size: 30px;
  font-weight: 500;
}

.navbar .logo img {
  width: 100px;
}

.navbar ul{
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}
.navbar ul li{
  margin: 0 5px;
}
.navbar ul li a{
  color: #f2f2f2;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 8px 15px;
  border-radius: 5px;
  letter-spacing: 1px;
  transition: all 0.3s ease;
}
.navbar ul li a.active,
.navbar ul li a:hover{
  color: #111;
  background: #fff;
}
.navbar .menu_btn i{
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  display: none;
}
input[type="checkbox"]{
  display: none;
}
.fas {
    color: white;
    display: none;
}
@media (max-width: 1200px){
  /* .navbar{
    padding: 0 40px 0 50px;
  } */
}
@media (max-width: 1100px) {
  .navbar .menu_btn {
    padding: 20px;
    border-radius: 50%;
  }
  .fas {
    color: white;
    display: block;
    font-size: 22px;
  }
  .click:checked ~ .menu_btn{
    content: "\f00d";
    background-color: white;
    padding: 20px;
    border-radius: 50%;
    align-items: center;
    transition: all 0.3s ease-in-out;
    
  }
  .click:checked ~ .menu_btn .fas {
    color: #1b1b1b;
 }
  .navbar ul{
    position: fixed;
    top: 100px;
    left: -100%;
    background: #111;
    height: 100vh;
    width: 100%;
    text-align: center;
    display: block;
    transition: all 0.3s ease;
  }
  .click:checked ~ ul{
    left: 0;
  }
  .navbar ul li{
    width: 100%;
    margin: 40px 0;
  }
  .navbar ul li a{
    width: 100%;
    margin-left: -100%;
    display: block;
    font-size: 20px;
    transition: 0.6s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }
  .click:checked ~ ul li a{
    margin-left: 0px;
  }
  .navbar ul li a.active,
  .navbar ul li a:hover{
    background: none;
    color: cyan;
  }
}
.content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: -1;
  width: 100%;
  padding: 0 30px;
  color: #1b1b1b;
}
.content div{
  font-size: 40px;
  font-weight: 700;
}

@media (max-width: 550px) {
    .navbar {
      height: 85px;
    }

    .nav_title {
        display: none;
    }

    .navbar .logo img{
      width: 75px;
    }

    .navbar ul {
      top: 85px;
    }
}

@media (max-width: 400px) {
  .navbar {
    height: 70px;
  }

  .navbar .logo img{
    width: 60px;
  }

  .navbar ul {
    top: 70px;
  }
}