
*,
*::before,
*::after {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
}

/* body {
    background-color: var(--secondary-dark-color);
    color: var(--text-dark);
} */

.banner {
    /* background-color: var(--accent-light); */
    margin: 0 auto;
    width: min(95%, 85rem);
}

/* #banner {
    height: 50rem;
} */

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(80px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.bannerContainer {
    /* animation: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) 0s 1 slideUp; */
    /* height: 100%; */
    margin: 0 auto;
    padding: 6.5rem 0;
    width: min(100%, 75rem);

    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.years {
    border-right: 3px solid var(--primary-light-color);
    width: min(40%, 188px);
    height: 25rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    font-family: manrope;
    font-size: 3.8rem;
    font-weight: 600;
}

.years>div {
    color: var(--tertiary-text-color);
}

.years>div:hover {
    color: var(--primary-text-color) !important;
    cursor: pointer;
}

.years>div:first-of-type {
    color: var(--primary-text-color);
}

.bannerContent {
    width: 65%;
    display: flex;
    flex-direction: column;

    color: var(--primary-text-color);
}

.bannerContent h3 {
    font-size: 3.8rem;
    font-family: lexend deca;
    font-weight: 400;
}

.bannerContent h2 {
    font-size: 6rem;
    font-family: poppins;
    font-weight: 600;
    line-height: 1.2em;
    word-spacing: 100vw;

    padding-top: 1.4em;
}
/* 
#sectionContainer
{
    width: 100%;
    background-color: #d9d9d9;
}

#achievementSection{
    height: 100rem;
} */


@media only screen and (max-width: 1400px) {


    .bannerContainer {
        padding: 6rem 0;
        justify-content: space-evenly;
        width: 90%;
    }

    .years {
        height: 20rem;
        font-size: 3rem;
        width: 20%;
    }

    .bannerContent h2 {

        padding-top: 1.4em;
        font-size: 4.8rem;
    }

    .bannerContent h3 {
        font-size: 3.5em;
    }
}

@media only screen and (max-width: 910px) {


    .bannerContainer {
        justify-content: space-around;
        width: 95%;
    }
    .bannerContent h3 {
        font-size: 2.5em;
    }.bannerContent h2 {
        font-size: 4.2em;
    }

}

@media only screen and (max-width: 768px) {
    .bannerContainer {
        padding: 8em 0;
        justify-content: space-around;
        width: 100%;
    }

    .years {
        font-size: 2.8em;
        width: 20%;
        height: 18rem;
        justify-content: space-between;
    }

    .bannerContent {
        width: 70%;
    }

    .bannerContent>h3 {
        font-size: 1.8rem;
    }
    
    .bannerContent>h2 {
        padding-top: 1.5em;
        font-size: 4em;
    }
}

@media only screen and (max-width: 700px) {
    

    .bannerContainer {
        padding: 3em 0;
        text-align: center;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        width: 100%;
    }

    .years {
        padding-top: .5em;
        font-size: 2.2em;
        width: 40%;
        height: 18rem;
        justify-content: space-evenly;
        border-right: none;
        border-top: 3px solid #46e0e0
    }

    .bannerContent>h3{
        font-size: 2em;
    }

    .bannerContent>h2{
        padding-top: 1em;
        font-size: 3em;
        padding-bottom: 1em;
    }
}

@media only screen and (max-width: 450px)
{
    .bannerContent>h2{
        font-size: 2.2em;
    }
}