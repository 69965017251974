.blogsPage {
    margin: 20px auto !important;
    max-width: 1400px;
    margin: auto;
}

.horizontalRule {
    height: 3px;
    width: 90%;
    background-color: var(--primary-light-color);
    margin: 50px auto;
}

.heading {
    font-size: 32px;
    color: var(--primary-text-color);
    text-align: center;
    margin: 50px auto;
}


.blogsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    place-items: center;
    width: 90%;
    margin: auto;
    gap: 30px;
}

.blogCard {
    height: 400px;
    width: 300px;
    overflow: hidden;
    background-color: var(--primary-dark-color);
}

.blogCard .cardSlider {
    transition: all 500ms;
    translate: 0 0;
}

.blogCard:hover .cardSlider {
    translate: 0 -150px;
}

.cardImage {
    display: block;
    height: 150px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transition: all 500ms;
}

.blogCard:hover .cardImage {
    opacity: 0;
}

.bottomContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    height: 250px;
    font-size: 18px;
    color: var(--secondary-text-color);
}

.cardHeading {
    font-size: 24px;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardDescription {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cardDate {
    font-size: 14px;
}

.arrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    font-size: 20px;
    color: var(--primary-light-color);
    height: 150px;
    opacity: 0;
    translate: 0 50px;
    transition: all 500ms 200ms;
}

.blogCard:hover .arrow {
    translate: 0 0;
    opacity: 1;
}

.searchWrapper {
    display: flex;
    width: 90%;
    flex-wrap: wrap;
    margin: 50px auto;
    gap: 5px;
}

.searchWrapper * {
    display: inline-block;
    min-width: 200px;
    height: 32px;
    margin: 0 auto;
    box-sizing: border-box;
    border: 1px solid var(--primary-light-color);
    color: var(--primary-text-color);
    background-color: var(--primary-dark-color);
    padding: 0 10px;
}

.searchInput {
    height: 30px;
    flex-grow: 1;
    flex-shrink: 1;
}

@media screen and (max-width: 810px) {
    .horizontalRule {
        display: none;
    }
    .searchWrapper * {
        border: 1px solid var(--primary-light-color);
    }
}

@media screen and (max-width: 700px) {
    .searchWrapper{
        width: 280px;
    }
    .dateInput {
        width: 100%;
    }
}

.blogImage {
    display: block;
    width: 100%;
    margin: auto;
    max-height: 500px;
}

.blogContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: var(--primary-text-color);
    padding: 40px 60px;
    border-left: 2px solid var(--primary-light-color);
}

.blogHeading {
    font-size: 60px;
    margin-bottom: 5px;
}

.blogDate {
    font-size: 22px;
    color: var(--tertiary-text-color);
    margin: 20px 0 0;
}

.blogContent {
    font-size: 22px;
    color: var(--secondary-text-color);
}

.blogPara {
    margin: 20px 0;
}

.h1 {
    font-size: 56px;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.h2 {
    font-size: 48px;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.h3 {
    font-size: 40px;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.h4 {
    font-size: 32px;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.h5 {
    font-size: 22px;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.h6 {
    font-size: 16px;
    border-bottom: 1px solid var(--tertiary-text-color);
    padding-bottom: 7px;
    margin-bottom: 5px;
}

.articleImage {
    display: block;
    height: 500px;
    aspect-ratio: 1;
    margin: auto;
}

@media screen and (max-width: 1000px) {
    .blogHeading {
        font-size: 56px;
    }
    .h1 {
        font-size: 48px;
    }
    
    .h2 {
        font-size: 40px;
    }
    
    .h3 {
        font-size: 32px;
    }
    
    .h4 {
        font-size: 24px;
    }
    
    .h5 {
        font-size: 20px;
    }
    
    .h6 {
        font-size: 16px;
    }
    .articleImage {
        height: 400px;
    }
}

@media screen and (max-width: 700px) {
    .blogContainer {
        padding: 40px;
    }
    .blogHeading {
        font-size: 45px;
    }
    .h1 {
        font-size: 40px;
    }
    
    .h2 {
        font-size: 36px;
    }
    
    .h3 {
        font-size: 32px;
    }
    
    .h4 {
        font-size: 24px;
    }
    
    .h5 {
        font-size: 20px;
    }
    
    .h6 {
        font-size: 16px;
    }
    .articleImage {
        height: 300px;
    }
}

@media screen and (max-width: 500px) {
    .blogContainer {
        padding: 20px 10px;
    }
    .blogHeading {
        text-align: center;
        font-size: 40px;
    }
    .articleImage {
        height: 250px;
    }
    .blogPara {
        text-align: center;
    }
    .blogDate {
        margin: auto;
    }
}