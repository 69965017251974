.carouselContainer {
    width: 800px;
    height: 500px;
    margin: auto;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.carouselSlide {
    display: flex;
    width: 100%;
    height: 100%;
}

.carouselSlide .imageContainer {
    display: block;
    height: 100%;
    min-width: 100%;
}

.imageContainer img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.carouselContainer .btn {
    display: grid;
    place-items: center;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    background-color: rgba(255, 255, 255, 0.4);
    background-image: url("../../Assets/Images/left_arrowhead.png");
    background-repeat: no-repeat;
    background-size: cover;
    filter: invert(0.8);
    cursor: pointer;
    border: 5px solid rgba(255, 255, 255, 0.05);
    border-radius: 5px;
}

.carouselContainer .btn:hover,
.carouselContainer .btn:focus {
    background-color: rgba(255, 255, 255, 0.7);
}

.carouselContainer .btn:focus {
    border: 1px solid black;
}

.carouselContainer .prev {
    left: 1rem;
    transform: rotate(180deg) translateY(50%) ;
}

.carouselContainer .next {
    right: 1rem;
}

.carouselMeter {
    width: fit-content;
    margin: 20px auto;
}

.carouselMeter .circle {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    margin: 0 15px;
}

@media screen and (max-width: 810px) {
    .carouselContainer {
        display: none;
    }
    .carouselMeter {
        display: none;
    }
}