/* @import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@200;400;500;700&family=Manrope:wght@300;500;700&family=Poppins:wght@200;400;500;600&display=swap'); */

*,
*::before,
*::after {
    scroll-behavior: smooth;
}

#achSection {
    padding-bottom: 5em;
    margin: 0 auto;
    width: min(95%, 85rem);
    /* color: var(--text-dark); */
}

#achSectionContainer {
    background-color: #D9D9D9;
    font-size: 20px;
}


.achCardBox {
    padding-top: 5em;
    display: flex;
    color: #1A1A1A;
    justify-content: space-between;
}

.achCardBox:nth-of-type(even) {
    flex-direction: row-reverse;
}


.achCardContent {
    width: 45%;
}


.achCardContent>h2 {
    font-size: 2.4em;
    color: #333;
}

.achCardContent>p {
    font-family: poppins;
    padding-top: 4em;
    font-weight: 400;
    font-size: 1.2em;
}

.achCardCover {
    /* background-image: url(https://images.unsplash.com/photo-1662222234305-384b292c4013?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80); */
    background-size: cover;
    width: 45%;
}

.achCardCover>img {
    width: 100%;
}

.achCardCover>p {
    font-family: poppins;
    letter-spacing: 0.02em;
    font-size: 1.2em;
    font-weight: 600;
}

@media only screen and (max-width: 1200px) {

    .achCardBox {
        padding-top: 5em;
        justify-content: space-evenly;
    }

    .achCardContent>p {
        padding-top: 3em;
    }

}

@media only screen and (max-width: 910px) {

    .achCardBox {
        padding-top: 3em;
    }

    .achCardContent>p {
        font-size: 1.1em;
    }

    .achCardCover>p {
        font-size: 1.2em;
    }

    .achCardCover {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 768px) {
    .achCardBox {
        padding-top: 1em;
        padding-bottom: 3em;
        flex-direction: column;
        text-align: center;
        align-items: center;
    }

    #achSection {
        padding-bottom: 0em;
    }

    .achCardBox:nth-of-type(even) {
        flex-direction: column;
    }

    .achCardContent {
        width: 80%;
    }

    .achCardContent>h2 {
        background-color: rgba(0, 151, 158, 0.23);
    }

    .achCardContent>p {
        font-size: 1em;
    }

    .achCardCover {
        width: 80%;
        padding-top: 3em;
        flex-direction: column;
    }

    .achCardCover>img {
        padding-top: 0em;
        padding-bottom: 3em;
    }

    .achCardCover>p {
        padding-bottom: 3em;
        border-bottom: solid 3px var(--secondary-dark-color);

    }

}

@media only screen and (max-width: 600px) {

    .achSectionContainer {
        padding: 1em 0;
    }

    .achCardBox {
        padding-top: 1em;
    }

    .achCardContent {
        width: 90%;
    }

    .achCardCover {
        width: 90%;
    }

    .achCardContent > h2 {
        font-size: 2em;
    }

    .achCardContent>p {
        padding-top: 2em;
    }
}

@media only screen and (max-width: 450px) {

    .achCardBox {
        padding-top: 1em;
        padding-bottom: 2em;
    }

    .achCardContent>p {
        font-size: 0.9em;
    }

    .achCardCover>p {
        padding-bottom: 2em;
    }
}